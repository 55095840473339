// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-apteki-js": () => import("./../../../src/pages/apteki.js" /* webpackChunkName: "component---src-pages-apteki-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-ddom-js": () => import("./../../../src/pages/ddom.js" /* webpackChunkName: "component---src-pages-ddom-js" */),
  "component---src-pages-diagnostyka-js": () => import("./../../../src/pages/diagnostyka.js" /* webpackChunkName: "component---src-pages-diagnostyka-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-poradnie-js": () => import("./../../../src/pages/poradnie.js" /* webpackChunkName: "component---src-pages-poradnie-js" */),
  "component---src-pages-projekty-unijne-informatyzacja-js": () => import("./../../../src/pages/projekty-unijne/informatyzacja.js" /* webpackChunkName: "component---src-pages-projekty-unijne-informatyzacja-js" */),
  "component---src-pages-projekty-unijne-js": () => import("./../../../src/pages/projekty-unijne.js" /* webpackChunkName: "component---src-pages-projekty-unijne-js" */),
  "component---src-pages-projekty-unijne-wypozyczalnia-js": () => import("./../../../src/pages/projekty-unijne/wypozyczalnia.js" /* webpackChunkName: "component---src-pages-projekty-unijne-wypozyczalnia-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-clinics-js": () => import("./../../../src/templates/clinics.js" /* webpackChunkName: "component---src-templates-clinics-js" */),
  "component---src-templates-diagnostics-js": () => import("./../../../src/templates/diagnostics.js" /* webpackChunkName: "component---src-templates-diagnostics-js" */)
}

